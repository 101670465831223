import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { mapDefaultTasq } from '@/utils/tasqs';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import workflowApolloClient from '@/lib/appsync/workflow';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import getListEnabledWells from '@/graphql/workflow/queries/getListEnabledWells.graphql';
import isOnline from 'is-online';
import localforage from '@/lib/localForage';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'assetsModule',
  store,
})
class AssetsModule extends VuexModule {
  enabledWells: any = [];
  producingWells: TasqJob[] = []
  activeTasq: TasqJob|null|undefined = null;


  @Mutation
  setEnabledWells(data): void {
    this.enabledWells = data;
  }


  @Mutation
  setProducingWells(data): void {
    this.producingWells = data;
  }


  @Mutation
  setActiveProducingTasq(wellName: any): void {
    this.activeTasq = this.producingWells.find((i) => i.wellName === wellName);
  }



  @Action
  async getEnabledWells(): Promise<void> {
    let items: any = [];
    if(await isOnline()) {
      const {
        data: {
          list_enabled_wells: {
            enabled_wells: wells,
          },
        },
      } = await workflowApolloClient.query({
        query: getListEnabledWells,
        variables: {
          operator: getConfigEnv('OPERATOR_LOWERCASED'),
        },
      });
      items = wells;
      await localforage.setItem('getEnabledWells', wells)
    } else {
      const result = await localforage.getItem('getEnabledWells');
      if(result) {
        items = result;
      }
    }


    items = items.map((i) => {
      const parsed = JSON.parse(i);
      return {
        wellName: parsed.NodeID,
        text: parsed.NodeID,
        value: parsed.NodeID,
        id: parsed.NodeID,
        engineerType: 'Producing',
        predictionType: 'Producing',
        assignee: {},
        route: parsed.Route,
        routeId: parsed.RouteID,
        level: parsed.Level,
        wells: parsed.Wells
      };
    });

    const producingWells = jsonParse(JSON.stringify(items)).map(
      (t: TasqJob) => mapDefaultTasq(t),
	  );

    this.setProducingWells(producingWells)

    items.sort((a, b) => a.wellName.localeCompare(b.wellName));

    // Remove duplicate wells
    var added_wells: any[] = []
    var results: any[] = []
    for (var b = 0; b < items.length; b++) {
      var containsWell = (added_wells.indexOf(items[b].wellName) > -1);
      if (!containsWell) {
        added_wells.push(items[b].wellName)
        results.push(items[b])
      }
    }
    this.setEnabledWells(results);
  }
}

export default getModule(AssetsModule);
